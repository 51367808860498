import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { allServiceImagesByNameAtom } from "../serviceImagesQuery.ts";
import type { ServiceOs } from "./serviceOs.ts";

export function useServiceOs({ os_name, os_version }: ServiceOs) {
    const allImages = useSuspenseQueryAtom(allServiceImagesByNameAtom);

    const os = allImages.get(os_name);
    // const version = os?.versions.find((item) => item === os_version);
    return { os, title: `${os?.display_name ?? os_name} ${os_version}` };
}
