import TC from "./Table.module.css";

import type { ReactNode } from "react";
import { cn } from "../../utils/classNames.ts";

export interface DetailsTableProps {
    children?: ReactNode;
    compact?: boolean;
}

export function DetailsTable({ children, compact }: DetailsTableProps) {
    return (
        <table className={cn(TC.details, compact && TC.compact)}>
            <tbody>{children}</tbody>
        </table>
    );
}

export function DetailsHolder({ children }: { children: ReactNode }) {
    return <div className={TC.detailsHolder}>{children}</div>;
}
