import { Suspense } from "react";
import { Link } from "react-aria-components";
import { Loading } from "../../components/loading/Loading.tsx";
import { OsImageIcon } from "../../modules/compute/os/OsImageIcon.tsx";
import { allImagesQueryAtom } from "../../modules/compute/vmImagesQuery.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";

function VmImagesViewCustom() {
    const images = useSuspenseQueryAtom(allImagesQueryAtom);

    return (
        <>
            <AdminTitle title="VM Images" />

            <div className="p-2">
                <p className="pb-3 color-muted">
                    Manage and publish VM images from UI. Make sure the images actually exist. Read more from{" "}
                    <Link
                        href="https://warrenio.atlassian.net/wiki/spaces/WARP/pages/299008011/VM+images"
                        target="_blank"
                    >
                        here
                    </Link>
                    .
                </p>
            </div>

            <div>
                {images.map((item, index) => {
                    const { os_name, display_name, versions } = item;

                    const versionsString = versions.map((v) => v.display_name).join(", ");

                    return (
                        <div className="p1" key={index}>
                            <OsImageIcon os_name={os_name} className="color-grey-5 size-0.875rem" />
                            {display_name}

                            <div className="color-muted text-ellipsis">{versionsString}</div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export function VmImagesView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <VmImagesViewCustom />
        </Suspense>
    );
}
