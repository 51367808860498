import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { ServiceImage } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { configAtom } from "../../config.ts";
import { queryThen } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";

export type Response = ServiceImage[];

export const queryKey: QueryKey = ["service_images"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/user-resource/service/configurations", { signal }));
        },
    });
}

export const allServiceImagesQueryAtom = atomFromStandardQueryOptions(getQuery);

/** Map from OS name to image */
export const allServiceImagesByNameAtom = atom((get) =>
    queryThen(get(allServiceImagesQueryAtom), (data) => mapFrom(data, (image) => image.service_name)),
);

export const availableServiceImagesAtom = atom((get) => {
    return queryThen(get(allServiceImagesQueryAtom), (images) => {
        // TODO: admin can see not published versions???
        const { kubernetesEnabled } = get(configAtom);
        return kubernetesEnabled ? images : images.filter((item) => item.service_name !== "kubernetes");
    });
});
