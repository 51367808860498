import type { ManagedServiceEnum } from "@warrenio/api-spec/spec.oats.gen";
import { useAtomValue } from "jotai/react";
import type { BaseProps } from "../../../utils/baseProps.ts";
import { ServiceImageIcon } from "../ServiceImageIcon.tsx";
import { allServiceImagesByNameAtom } from "../serviceImagesQuery.ts";

export function ServiceOsImageIcon({ os_name, ...props }: { os_name: ManagedServiceEnum } & BaseProps) {
    const imagesByOsName = useAtomValue(allServiceImagesByNameAtom);
    const image = imagesByOsName?.data?.get(os_name);
    return <ServiceImageIcon image={image} {...props} />;
}
