import C from "./AdminLayout.module.css";

import type { ReactNode } from "react";
import { cn } from "../utils/classNames.ts";
import { useTitle } from "../utils/react/useTitle.ts";

export function AdminTitle({ title, children }: { title: string; children?: ReactNode }) {
    useTitle(title);

    return (
        <div className={cn(C.Title, "flex items-center justify-between p-2 gap-1")}>
            <h1 className="flex-shrink-0 font-size-default text-ellipsis whitespace-nowrap font-bold">{title}</h1>
            {children}
        </div>
    );
}
